import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import CalloutView from '../views/CalloutView.vue'
import CreateCallout from '../views/CreateCalloutView.vue'
import CallOutHistory from '../views/CalloutHistoryView.vue'
import Team from '../views/TeamView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'KNS | Home'
    }
  },
  {
    path: '/active-callouts',
    name: 'active-callouts',
    component: CalloutView,
    meta: {
      title: 'KNS | Active Callouts'
    }
  },
  {
    path: '/create-callouts',
    name: 'create-callouts',
    component: CreateCallout,
    meta: {
      title: 'KNS | Create Callouts'
    }
  },
  {
    path: '/callout-history',
    name: 'callout-history',
    component: CallOutHistory,
    meta: {
      title: 'KNS | Callout History'
    }
  },
  {
    path: '/team',
    name: 'team',
    component: Team,
    meta: {
      title: 'KNS | Team'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'KNS | Login'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
    meta: {
      title: 'KNS | Signup'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView,
    meta: {
      title: 'KNS | Forgot Passord'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// use the code below to show the title on each page
router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // Continue resolving the route
  next()
})

export default router

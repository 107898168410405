<template>
    <div class="create-callout">
        <div class="title-box">
            <div>
                <h3>KNS Boxer Portal</h3>
                <h6>Create Callout</h6>
            </div>
            <div class="date-holder">
                <span class="icon-calendar"></span>
                <p>{{currentDate()}}</p>
            </div>
        </div>
        <div class="content-wrapper create-callout-holder">
            <form>
                <section>
                    <p class="form-title">Select Store</p>
                    <label><span class="icon-down-arrow"></span>
                        <select name="store" id="">
                            <option value="">-- Select a store --</option>
                            <option value="">Will be populated once Jess adds the stores on the system</option>
                        </select>
                    </label>
                    <label><span class="icon-down-arrow"></span>
                        <select name="requested_by" id="">
                            <option value="">-- Requested By --</option>
                            <option value="">Will be populated once Jess adds the stores on the system</option>
                        </select>
                    </label>
                    <label>
                        <input type="text" placeholder="Store manager name" name="manager_name">
                    </label>
                    <label>
                        <input type="number" placeholder="Store manager contact" name="manager_contact">
                    </label>
                </section>
                <section>
                    <p class="form-title">Select Technician</p>
                    <label><span class="icon-down-arrow"></span>
                        <select name="technician" id="">
                            <option value="">-- Select technician --</option>
                            <option value="">Will be populated once Jess adds techs on the system</option>
                        </select>
                    </label>
                    <label>
                        <input type="text" placeholder="Technician name" name="tech_name">
                    </label>
                    <label>
                        <input type="number" placeholder="Technician contact" name="tech_contact">
                    </label>
                    <label>
                        <input type="email" placeholder="Technician email" name="tech_email">
                    </label>
                </section>
                <section>
                    <p class="form-title">Log number & Fault Description</p>
                    <label>
                        <input type="number" placeholder="Log number" name="log_number">
                    </label>
                    <div class="clear"></div>
                    <textarea name="fault_description" placeholder="Fault description"></textarea>
                </section>
                <button>+ Create Callout</button>
                <div class="error">This will show an error message should there be any</div>
                <img src="../assets/images/loader.svg" class="loader" alt="">
            </form>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    currentDate () {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    }
  }
}
</script>

<template>
    <div class="callout-history">
        <div class="title-box">
            <div>
                <h3>KNS Boxer Portal</h3>
                <h6>Callout History</h6>
            </div>
            <div class="date-holder">
                <span class="icon-calendar"></span>
                <p>{{currentDate()}}</p>
            </div>
        </div>
        <div class="content-wrapper callout-history-holder">
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
            <dl class="dl">
                <dt>Log No:</dt>
                <dd>B-08987876</dd>
                <dd class="status-type-closed">Closed</dd>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <dd class="date">Issued on: 30/05/2023</dd>
            </dl>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    currentDate () {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    }
  }
}
</script>

<template>
    <div class="team">
        <div class="title-box">
            <div>
                <h3>KNS Boxer Portal</h3>
                <h6>Team Details</h6>
            </div>
            <!-- <div class="date-holder">
                <span class="icon-calendar"></span>
                <p>{{currentDate()}}</p>
            </div> -->
            <button class="add-member" @click="open_add_member_modal">+ Add Team Member</button>
         </div>
         <div class="content-wrapper team-holder">
            <div class="left-holder">
                <div class="block-title">
                    <h5>Team Members</h5>
                    <div class="count">
                        <p>16</p>
                    </div>
                </div>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
                <dl>
                    <span>
                        <div class="img-holder">
                            <img src="../assets/images/logo/logo.png" alt="">
                        </div>
                        <div>
                            <dt>Team member name</dt>
                            <dd>Surname</dd>
                        </div>
                    </span>
                    <button class="delete-btn">Delete</button>
                </dl>
            </div>
            <div class="right-holder-2">
                <h3>Vellie T</h3>
                <form>
                    <input type="text" placeholder="Name" name="name">
                    <input type="text" placeholder="Surname" name="surnname">
                    <input type="number" placeholder="Contact" name="contact">
                    <input type="email" placeholder="Email" name="email">
                    <button>Update Vellie's Profile</button>
                    <div class="error">This will show an error message should there be any</div>
                    <img src="../assets/images/loader.svg" class="loader" alt="">
                </form>
            </div>
         </div>
         <div class="backdrop" v-if="backdrop" @click="close"></div>
         <transition leave-active-class="animated bounceOutUp">
            <div class="add-member-modal animated bounceInDown" v-if="add_member_modal">
                <span @click="close">X</span>
                <h3>Add a new team member</h3>
                <form>
                    <input type="text" v-model="new_team_member_name" placeholder="Name" name="name">
                    <input type="text" placeholder="Surame" name="surname">
                    <input type="number" placeholder="Contact" name="contact">
                    <input type="email" placeholder="Email" name="email">
                    <button>Add new team member</button>
                    <div class="error">This will show an error message should there be any</div>
                    <img src="../assets/images/loader.svg" class="loader" alt="">
                </form>
            </div>
         </transition>
    </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      backdrop: false,
      add_member_modal: false,
      // set error message
      error: '',
      // get new team member name
      new_team_member_name: '',
      new_team_member_surname: '',
      new_team_member_contact: '',
      new_team_member_email: ''
    }
  },
  methods: {
    currentDate () {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    },
    open_add_member_modal () {
      this.backdrop = true
      this.add_member_modal = true
    },
    close () {
      this.add_member_modal = false
      setTimeout(() => {
        this.backdrop = false
      }, 800)
    }
  }
}
</script>

<template>
    <div class="signup">
        <div class="signup-modal">
            <form action="">
                <img src="../assets/images/logo/logo.png" class="logo" alt="KNS Logo">
                <h4>SIGNUP</h4>
                <p>Create your profile for KNS Boxer management portal</p>
                <input type="text" placeholder="First name" name="name">
                <input type="text" placeholder="Last name" name="name">
                <input type="number" placeholder="Contact number" name="contact">
                <input type="email" placeholder="Email address" name="email">
                <input type="password" placeholder="Create password" name="password">
                <input type="password" placeholder="Confirm password" name="password">
                <router-link to="/"><button>SIGNUP</button></router-link>
                <!-- <div class="error-message">username or password do not match</div> -->
                <router-link class="router-link" to="/login"><p class="margin-top">Already have an account? | Login</p></router-link>
            </form>
        </div>
    </div>
</template>

<template>
  <div class="home">
    <div class="title-box">
      <div>
        <h3>KNS Boxer Portal</h3>
        <h6>Projects overview</h6>
      </div>
      <div class="date-holder">
        <span class="icon-calendar"></span>
        <p>{{currentDate()}}</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="left-holder">
        <div class="block-title">
          <h5>Stores</h5>
          <div class="count">
            <p>75</p>
          </div>
        </div>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
        <dl>
          <span>
            <div class="img-holder">
              <img src="../assets/images/logo/boxer.png" alt="">
            </div>
            <div>
              <dt>Boxer</dt>
              <dd>Description</dd>
            </div>
          </span>
        </dl>
      </div>
      <div class="right-holder">
        <div class="top">
          <div class="block-title">
            <h5>Callouts</h5>
            <div class="count">
              <p>4</p>
            </div>
          </div>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
            <!-- <router-link to="/active-callouts"><button>View</button></router-link> -->
          </dl>
        </div>
        <div class="bottom">
          <div class="block-title">
            <h5>Calls On Hold</h5>
            <div class="count">
              <p>2</p>
            </div>
          </div>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
          <dl>
            <span>
              <div>
                <dt>Example callout</dt>
                <dd>Start date: 10/03/2023</dd>
              </div>
            </span>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    currentDate () {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    }
  }
}
</script>

<template>
    <div class="active-callouts">
      <div class="title-box">
            <div>
                <label><span class="icon-search"></span>
                  <input type="text" placeholder="Search">
                </label>
            </div>
            <div class="button-holder">
              <button>Active</button>
              <button>On Hold</button>
            </div>
            <div class="date-holder">
                <span class="icon-calendar"></span>
                <p>{{currentDate()}}</p>
            </div>
        </div>
        <div class="content-wrapper callout-holder">
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-on-hold">On Hold</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
          <dl class="dl">
            <dt>Log No:</dt>
            <dd>B-08987876</dd>
            <dd class="status-type-active">Active</dd>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <dd class="date">Issued on: 30/05/2023</dd>
          </dl>
        </div>
    </div>
</template>
<script>

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      // meetings is an empty array populated by the response from the DB
      // We would normally loop through this array in our foreach, however
      // we have pushed the empty meetings array to our computed() method filteredSearch()
      // we will now loop through filtereSearch() as this has become the populated array
      // and allows us to do a filtered search on the frontend DOM
      callouts: [],

      // add search field
      search: ''
    }
  },
  methods: {
    currentDate () {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    }
  },
  computed: {
    filteredSearch () {
      return this.callouts.filter((callout) => {
        return callout.callout_title.toLowerCase().match(this.search.toLowerCase())
      })
    }
  }
}
</script>

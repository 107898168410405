<template>
    <div class="forgot-password">
        <div class="forgot-password-modal">
            <form action="">
                <img src="../assets/images/logo/logo.png" class="logo" alt="KNS Logo">
                <h4>RESET PASSWORD</h4>
                <p>Use your email associated with your KNS profile to reset your password</p>
                <input type="email" placeholder="Your profile email">
                <router-link to="/"><button>Reset Password</button></router-link>
                <!-- <div class="error-message">username or password do not match</div> -->
                <router-link class="router-link" to="/login"><p class="margin-top">Go back to login?</p></router-link>
            </form>
        </div>
    </div>
</template>

<template>
    <div class="login">
        <div class="login-modal">
            <form action="">
                <img src="../assets/images/logo/logo.png" class="logo" alt="KNS Logo">
                <h4>LOGIN</h4>
                <p>KNS Boxer management portal</p>
                <input type="text" placeholder="Username...">
                <input type="password" placeholder="Password">
                <router-link to="/"><button>LOGIN</button></router-link>
                <!-- <div class="error-message">username or password do not match</div> -->
                <router-link class="router-link" to="/signup"><p class="margin-top">Don't have an account? | Signup</p></router-link>
                <router-link class="router-link" to="/forgot-password"><p class="margin-top">Forgot password? | Reset Password</p></router-link>
            </form>
        </div>
    </div>
</template>

<template>
  <nav v-if="!isLoggedIn">
        <img src="./assets/images/logo/logo.png" alt="KNS Logo">
        <ul>
            <router-link to="/"><li @click="isActive = 1" :class="{'active-menu-item': isActive == 1}">Home</li></router-link>
            <router-link to="/active-callouts"><li @click="isActive = 2" :class="{'active-menu-item': isActive == 2}">Callouts</li></router-link>
            <router-link to="/create-callouts"><li @click="isActive = 3" :class="{'active-menu-item': isActive == 3}">Create Callouts</li></router-link>
            <!-- <li @click="isActive = 4" :class="{'active-menu-item': isActive == 4}">Calls on Hold</li> -->
            <router-link to="/callout-history"><li @click="isActive = 5" :class="{'active-menu-item': isActive == 5}">Callout History</li></router-link>
            <li @click="isActive = 6" :class="{'active-menu-item': isActive == 6}">Boxer Stores</li>
            <router-link to="/team"><li @click="isActive = 7" :class="{'active-menu-item': isActive == 7}">Team Details</li></router-link>
            <!-- <router-link to="/login"><li @click="login">Login</li></router-link>
            <router-link to="/signup"><li @click="signup">Signup</li></router-link> -->
        </ul>
    </nav>
  <router-view/>
</template>
<script>
export default {
  data () {
    return {
      isActive: false
    }
  },
  created () {
    this.isActive = 1
  }
}
</script>
